import React, { useRef, useState, useEffect } from 'react'
import tw from 'twin.macro'
import { css } from '@emotion/react'

import { CopySMStyle, CopyStyle, SubheadSMStyle } from './UI'
import Flickity from 'react-flickity-component'

import arrow from '../images/icons/footer-form-submit-arrow.svg'

const Testimonials = ({ testimonials, onWhite }) => {
  const carousel = useRef(null)
  const [activeDotIndex, setActiveDotIndex] = useState(0)
  const [flickitySettled, setFlickitySettled] = useState(false)

  useEffect(() => {
    if (carousel.current) {
      carousel.current.flkty.on('change', (e) => {
        setActiveDotIndex(e)
      })

      carousel.current.flkty.on('ready', () => {
        setFlickitySettled(true)
      })
    }
  }, [])

  const prevSlide = () => {
    carousel.current.flkty.previous()
  }

  const nextSlide = () => {
    carousel.current.flkty.next()
  }

  return (
    <>
      {testimonials && (
        <>
          <Flickity
            className="carousel"
            css={[
              flickitySettled &&
                css`
                  .flickity-slider {
                    height: 100%;
                  }
                `,
            ]}
            ref={carousel}
            options={{
              prevNextButtons: false,
              pageDots: false,
              cellAlign: 'left',
              resize: true,
              wrapAround: false,
              adaptiveHeight: false,
              setGallerySize: true,
            }}
            static
          >
            {testimonials.map((testimonial, index) => (
              <div
                key={`testimonial-${index}`}
                tw="flex flex-col justify-between border border-black w-full min-h-full py-7 px-8 mr-6 lg:(w-[500px] py-14 px-16)"
              >
                {/* Copy */}
                <p css={[CopyStyle, tw`mb-8`]}>{testimonial.copy.copy}</p>
                {/* Author */}
                <div tw="flex items-center justify-start">
                  <div>
                    <div
                      css={[
                        tw`rounded-full w-16 h-16 mr-5 flex items-center justify-center bg-white`,
                        onWhite && tw`bg-[#F1FAFD]`,
                      ]}
                    >
                      {testimonial.initials}
                    </div>
                  </div>
                  <div>
                    <h3 css={[SubheadSMStyle, tw`mb-1.5`]}>
                      {testimonial.name}
                    </h3>
                    <h4 css={[CopySMStyle]}>{testimonial.role}</h4>
                  </div>
                </div>
              </div>
            ))}
          </Flickity>
          {/* Flickity controls */}
          <div tw="mt-10">
            {/* Dots */}
            <div tw="w-[200px] mx-auto relative">
              {testimonials.map((slide, index) => (
                <hr
                  key={`dot-${index}`}
                  css={[
                    tw`inline-block border-[#202C3A]`,
                    `width: calc(100% / ${testimonials.length})`,
                    activeDotIndex === index && tw`border-[#FF4D00]`,
                  ]}
                />
              ))}
            </div>
            {/* Arrows */}
            <div tw="mt-2 flex justify-center">
              <button tw="mx-2" onClick={prevSlide}>
                <img
                  src={arrow}
                  css={[
                    tw`w-8`,
                    css`
                      transform: rotate(180deg);
                    `,
                  ]}
                />
              </button>
              <button tw="mx-2" onClick={nextSlide}>
                <img src={arrow} tw="w-8" />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Testimonials
